import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../components/antd/less/antMotionStyle.less'

import pic1 from '../../images/bug2.jpg'
import pic2 from '../../images/bug3.jpg'
import pic3 from '../../images/bug4.jpg'
import pic4 from '../../images/bug5.jpg'

export default class TraceSource extends React.PureComponent {
  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { products, productGroups = [] } = strapi

    return (
      <Layout
        curIndex={1}
        products={products}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='植物病虫害分析' />
        <div className='core-tech-wrap bug-source-wrap'>
          <div className='main-img'></div>
          <div className='main-con'>
            <h2>概述</h2>
            <p className='description-text'>
              易见纹语科技基于全球领先的Al图像视频分析技术，打造了完全自主知识产权的AI智能植物病虫害识别系统。该系统支持用户或摄像
              采集设备直接拍摄或上传症状照片，结合人工智能视觉分析技术，通过机器学习及大数据匹配，对拍摄或上传的植株病虫害发病部位(如
              叶片、花、茎、根部等)照片或视频进行识别，自动判断植株的病虫害发病类别，并以此为依据给出病虫害的防治方案，防止农药滥用情
              况的发生。目前已支持三七、木薯、西红柿、葡萄等十多种作物。
            </p>
          </div>
        </div>
        <div
          className='core-tech-wrap bug-source-wrap'
          style={{ backgroundColor: '#F5F5F5' }}
        >
          <div className='main-con'>
            <h2>技术优势及特色</h2>
            <div style={{ backgroundColor: '#FFF', padding: '2rem 3rem' }}>
              <p className='description-text no-indent'>
                <div
                  style={{
                    width: '43%',
                    float: 'right',
                    paddingLeft: '2rem',
                    /* marginRight: '2rem', */
                  }}
                >
                  <img src={pic1}></img>
                </div>
                <span style={{ fontWeight: 'bold' }}>样本：</span>
                <br />
                <ul className='no-style-ul'>
                  <li style={{ margin: '10px 0px' }}>
                    目前为止，智能病虫害识别算法可识别16种农作物的70多种常见病虫害；
                  </li>
                  <li style={{ margin: '10px 0px' }}>
                    超过10万的病虫害样本总量；
                  </li>
                  <li style={{ margin: '10px 0px' }}>
                    病虫害单一样本数量不少于50张；
                  </li>
                  <li style={{ margin: '10px 0px' }}>
                    每种病虫害均提供对应的样本病例图片。
                  </li>
                </ul>
                <br />
                <span style={{ fontWeight: 'bold' }}>识别精度优势：</span>
                <br />
                <span style={{ margin: '10px 0px' }}>
                  目前的识别精度都能达到89%及以上{' '}
                </span>
                <br />
                <br />
                <span style={{ fontWeight: 'bold' }}>算法优势：</span>
                <br />
                <ul className='no-style-ul'>
                  <li style={{ margin: '10px 0px' }}>
                    精准：使用当前最新的多个神经网络结构(EfficientNet,
                    ResNeXt,vision-transformer等 )构建病虫害识别算法的主干网络,
                    并通过精心设计的模型融合技术平衡多个神经网络的输出结果，以达到最佳的识别精度。此外，针对病虫害特点，在主干网络上添加了自研的通道注
                    意力模块，有效的提升了模型对图片共性细节的辨识度。
                  </li>
                  <li style={{ margin: '10px 0px' }}>
                    稳定：定制的数据增强技术，对拍摄图片的亮度、对比度具有高适应性；自研了一整套图片质量预识别技术，自动对拍摄图片的角度、清
                    晰度进行辨别，提示用户校正,增加用户拍摄图片的质量稳定性。
                  </li>
                  <li style={{ margin: '10px 0px' }}>
                    高效:模型训练时使用自研的基于余弦距离及正则化的监督函数,提高模型训练的针对性，可以使用少量的样本数据进行快速迁移学习，
                    因此可以快速扩展到多种作物的病虫害识别应用种。
                  </li>
                </ul>
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className='core-tech-wrap bug-source-wrap'>
          <div className='main-con'>
            <h2>部分案例</h2>
            <div className='float-box'>
              <div className='float-box-col49 shadow'>
                <div>
                  <img src={pic2} />
                </div>
                <div
                  style={{
                    lineHeight: '4rem',
                    fontSize: '1.3rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  三七植株病虫害识别
                </div>
              </div>
              <div
                className='float-box-col49 shadow'
                style={{ float: 'right' }}
              >
                <div>
                  <img src={pic3} />
                </div>
                <div
                  style={{
                    lineHeight: '4rem',
                    fontSize: '1.3rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  木薯叶片病虫害识别（Kaggle竞赛金牌*）
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='core-tech-wrap solution-wrap'>
          <img src={pic4} />
        </div>
      </Layout>
    )
  }
}
export const query = graphql`
  query {
    strapi {
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
